import PropTypes from 'prop-types';

import Page from 'core/components/Page';
import Link from 'site/components/Link';
import H1 from 'core/components/H1';
import MarkdownWrapper from 'core/components/MarkdownWrapper';

import { capitalize, replaceCurrentYear } from 'core/utils/string-helper';

import { PageIndent, Indent } from 'site/components/Wrappers';

import { LinkSmall } from 'site/components/Texts';

import styles from './index.styl';


const customHeaders = {
  'shou-biznes': 'Новости шоу-бизнеса',
  'nekrolog': 'Некрологи: последние новости',
  'issledovaniya': 'Исследования',
  'premii': 'Новости музыкальных премий',
  'kinematograf': 'Новости мира музыки и кино',
  'teleshou': 'Новости телешоу',
  'videoigry': 'Новости мира видеоигр',
};

export default function PageWrapper({ tag, topicType, children }) {
  const {
    attributes: {
      title,
      description,
      slug,
      meta_title: metaTitle,
      meta_description: metaDescription,
      tag_type: tagType,
    },
  } = tag || {};

  const pageHeader = customHeaders[slug] || title;
  const titleMeta = replaceCurrentYear(metaTitle);
  const descriptionMeta = replaceCurrentYear(metaDescription);
  const pageTitle = capitalize(title);

  const isGenre = tagType === 'genre';

  let seoTitle;
  let seoDescription;

  if (tagType === 'artist') {
    seoTitle = `${pageTitle} - последние новости на сегодня`;
    seoDescription = `Главные новости о персоне ${titleMeta || pageTitle}
    на go.zvuk.com. Будьте в курсе последних новостей из жизни любимых артистов с медиа «Звук».`;
  } else if (tagType === 'genre') {
    seoTitle = `${pageTitle} - главные новости на сегодня`;
    seoDescription = `${titleMeta || pageTitle}: главные новости на go.zvuk.com. Будьте в курсе последних новостей из мира музыки вместе с медиа «Звук»`;
  } else {
    seoTitle = `${pageTitle} - самое свежее и актуальное`;
    seoDescription = `${titleMeta || pageTitle}: Вся самая Актуальная информация. Самые свежие Статьи и Новости мира музыки!`;
  }

  let headerText = pageHeader;

  if (topicType === 'news') {
    headerText = `Новости ${pageHeader}`;
  }

  if (topicType === 'articles') {
    headerText = `Статьи ${pageHeader}`;
  }

  return (
    <Page
      title={titleMeta || seoTitle}
      description={descriptionMeta || seoDescription}
    >
      <PageIndent>
        <div className={styles.tagHeading}>
          <H1>{headerText}</H1>
          {isGenre && (
            <LinkSmall>
              <Link
                to='/genres'
                type='genres'
                size='small'
              >
                Все жанры
              </Link>
            </LinkSmall>
          )}
        </div>
        <Indent top={20} />
        {!!description && (
          <div style={{ marginBottom: '20px' }}>
            <MarkdownWrapper>{description}</MarkdownWrapper>
          </div>
        )}
        {children({ tagType })}
      </PageIndent>
    </Page>
  );
}

PageWrapper.propTypes = {
  tag: PropTypes.object,
  topicType: PropTypes.string,
};
