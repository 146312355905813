import { get } from 'core/libs/lodash';
import resolveRelationships from 'core/utils/relationships';
import { getDescription } from 'core/utils/content';

const metaRelationships = resolveRelationships(['seo_meta', 'content'], {}, {
  content: { widgets: [] },
});

export function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * Возвращает заголовок топика учитывая постраничную навигацию
 */
export function getTitle(headline, page, widgets) {
  let title = headline;

  const pageBrakes = (widgets || [])
    .filter(widget => widget.type === 'pageBreak');

  if (page > 1 && page <= pageBrakes.length + 1) {
    title = pageBrakes[page - 2].attributes.title;
  }

  return title;
}

/**
 * Возвращает метаданные для артистов и группы, приведенные к шаблону
 */
export function metaBuilder(content, metaType) {
  const {
    announce,
    headline,
    alternative_headline: altHeadline,
    topic_type: topicType,
  } = content.attributes;

  const {
    seo_meta: {
      title: seoTitle,
      description: seoDescription,
    },
    content: {
      widgets,
    },
  } = metaRelationships(content);

  const commonTitle = seoTitle || altHeadline || headline;
  const commonDescription = seoDescription || announce || getDescription(widgets);

  const metaMap = {
    title: {
      artist_person: `${commonTitle} - биография, личная жизнь, песни, фото`,
      artist_group: `${commonTitle} - история создания, состав группы, песни, альбомы, фото`,
      default: commonTitle,
    },
    description: {
      artist_person: `Биография ${commonTitle}: личная жизнь, юность, детство, семья, музыкальная карьера, возраст и национальность исполнителя, дискография, популярные песни и альбомы, лучшие клипы, жанр музыки, артист сейчас.`,
      artist_group: `Биография группы ${commonTitle}: состав, история создания, популярные песни и музыкальные альбомы, дискография, лучшие клипы, концерты, жанр музыки, фото, группа сейчас.`,
      default: commonDescription,
    },
  };

  return metaMap[metaType][topicType] || metaMap[metaType].default;
}

/**
 * Возвращает url указанной версии аватара.
 * @param {Object} [person={}] - объект, соответствующий модели person swagger-схемы
 * @param {string} [version=original] - тип аватара, который нужно получить
 * @returns {Object} Объект аватара указанной версии или аватар-заглушка.
 */

export function getAvatar(person = {}, version = 'original') {
  const path = [
    'attributes',
    'avatar',
    'attributes',
    'versions',
    version,
  ];

  const defaultAvatar = {
    rel_url: require('site/images/avatar.png'),
    width: 400,
    height: 400,
  };

  return get(person, path, defaultAvatar);
}


/**
 * Возвращает src указанной версии image.
 * @param {Object} [image={}] - объект, соответствующий модели image
 * @param {string} [version=original] - тип аватара, который нужно получить
 * @returns {(Object|undefined)} Объект src указанной версии или undefined.
 */

export function getImageSrc(image = {}, version = 'original') {
  const path = [
    'attributes',
    'versions',
    version,
  ];

  return get(image, path);
}

// Возвращает первый требуемый виджет

export  function getWidget(topic, widgetType) {
  const relationships = resolveRelationships(['content'], {}, {
    content: {
      widgets: [],
    },
  });

  const {
    content: {
      widgets,
    },
  } = relationships(topic);

  if (!(Array.isArray(widgets) && widgets.length)) return null;

  return widgets.find(item => item && item.type === widgetType) || null;
}

// Проверяет на наличие тега Новинки

export function isNovinki(tags) {
  return Boolean(tags.find(tag => tag.attributes.slug === 'novinki'));
}

/**
 * Формирует строку из названий тегов
 *
 * @param {Object[]} tags - массив тегов
 * @param {Function} filterCallback - коллбек, который будет передан в tags.filter()
 */
export function buildTagTitlesString(tags, filterCallback) {
  return tags
    .filter(filterCallback)
    .map(tag => tag.attributes.title)
    .join(', ');
}

/**
 * Проверяет наличие более одного заголовка для навигации
 */
export function checkNavigationHeaders(content) {
  const headers = content.filter((el) => el.attributes.body && /##(.+)/.test(el.attributes.body))
    .map((el) => el.attributes.body.match(/##\s(.+)/g))
    .filter(Boolean)
    .reduce((result, current) => ([
      ...result,
      ...current,
    ]), []);
  return headers.length > 1;
}

/**
 * Получаем виджеты с исключёнными хитами
 */
export function getWidgetsWithExcludedEmbeds(widgets) {
  return widgets.reduce((result, widget, index) => {
    if (widget.type === 'snippet' && /https:\/\/zvuk.com\/embed/.test(widget.attributes.html)) {
      const prevEl = result[index - 1];
      const prevBody = prevEl?.attributes?.body;
      const newBody = prevBody?.replace(/\n\n##\s.[\s\S]+$/, '');

      if (newBody) {
        result[index - 1].attributes.body = newBody;
      }
    } else {
      result.push(widget);
    }
    return result;
  }, []);
}

/**
 * Возвращает количество полных лет между датами или null,
 * если один из аргументов не является датой.
 * @param {Date} date1
 * @param {Date} date2
 */
export function getFullYearsDelta(date1, date2) {
  const wrongDateValue = 'Invalid date';

  if (date1.toString() === wrongDateValue || date2.toString() === wrongDateValue) return null;

  const yearsDelta = date2.getFullYear() - date1.getFullYear();
  const monthsDelta = date2.getMonth() - date1.getMonth();
  if (monthsDelta < 0) return yearsDelta - 1;
  if (monthsDelta > 0) return yearsDelta;
  const daysDelta = date2.getDate() - date1.getDate();
  return daysDelta < 0
    ? yearsDelta - 1
    : yearsDelta;
}

export const isStarTopic = topicType => (topicType === 'artist_person') || (topicType === 'artist_group');
export const isSpecialTagType = tagType => tagType === 'genre' || tagType === 'geo';

export const getArtistsTag = tags => tags.find(star => star.attributes.tag_type === 'artist');
export const getGenreTags = tags => tags
  .filter(tag => tag.attributes.tag_type === 'genre')
  .map(tag => tag.attributes.slug);
