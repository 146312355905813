import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import Select, { components }  from 'react-select';

import withTheme from 'core/components/theme';

import Icon from 'site/icons/SelectPointer.svg';

class SelectWrapper extends PureComponent {
  getCustomStyles = () => {
    const {
      theme: {
        colors: {
          primary,
          layout,
          divider,
          gray90,
          gray60,
        },
        fonts: {
          text,
        },
        animations: {
          hover: hoverAnimations,
        },
      },
    } = this.props;
    return ({
      menu: provided => ({
        ...provided,
        marginTop: 4,
        marginBottom: 0,
        overflow: 'hidden',
        zIndex: 10,
        background: layout,
      }),
      menuList: provided => ({
        ...provided,
        font: `14px/17px ${text}`,
        maxHeight: 200,
        paddingTop: 0,
        paddingBottom: 0,
      }),
      control: (provided, state) => ({
        ...provided,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: divider,
        transition: hoverAnimations,
        ':hover': {
          borderColor: state.isFocused ? 'transparent' : gray60,
        },
      }),
      singleValue: provided => ({
        ...provided,
        font: `14px/17px ${text}`,
        margin: 0,
      }),
      input: (provided) => ({
        ...provided,
        font: `14px/17px ${text}`,
        paddingLeft: '0',
      }),
      option: (provided) => {
        return ({
          ...provided,
          font: `14px/17px ${text}`,
          color: primary,
          cursor: 'pointer',
          backgroundColor: layout,
          transition: hoverAnimations,
          ':hover': {
            backgroundColor: gray90,
          },
        });
      },
      noOptionsMessage: (provided) => {
        return ({
          ...provided,
          color: gray60,
        });
      },
    });
  };

  DropdownIndicator = innerProps => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...innerProps}>
          <Icon />
        </components.DropdownIndicator>
      )
    );
  };

  render() {
    return (
      <Select
        instanceId={this.props.name}
        components={{ DropdownIndicator: this.DropdownIndicator }}
        styles={this.getCustomStyles()}
        {...this.props}
      />
    );
  }
}

SelectWrapper.defaultProps = {
  noOptionsMessage: () => 'Ничего не найдено',
  loadingMessage: () => 'Загрузка...',
};

SelectWrapper.propTypes = {
  /** Текст, отображающийся при пустом списке */
  noOptionsMessage: PropTypes.func,
  /** Текст, отображаемый при загрузке элементов списка */
  loadingMessage: PropTypes.func,
  /** @ignore */
  theme: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
};

const SelectWrapperWithHOCs = withTheme(SelectWrapper);
SelectWrapperWithHOCs.displayName = 'SelectWrapper';

export { SelectWrapper as StorybookComponent };

export default SelectWrapperWithHOCs;
