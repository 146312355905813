import useRequest from 'core/hooks/useRequest';
import { topicsQuery } from 'core/queries/tagTopics';

import { processQueryResults } from 'core/utils/query';
import { denormalizeData } from 'core/utils/api';


export default function useTypeSwitcher({ location, match, history }) {
  const {
    params: {
      level_1: level1,
      level_2: level2,
      tagType,
    },
  } = match;

  const entityType = tagType ? 'tag' : 'rubric';
  const entitySlug = tagType ? level2 : level1;

  const commonApiProps = {
    location,
    match,
    history,
    [entityType]: entitySlug,
    limit: 1,
    fields: null,
  };

  const {
    data: [
      typeSwitcherNews = [],
      typeSwitcherStars = [],
      typeSwitcherArticles = [],
    ],
    isLoading: switcherResultsAreLoading,
  } = processQueryResults(useRequest({
    queries: [
      topicsQuery({ ...commonApiProps, topic_type: 'news' }, { select: denormalizeData }),
      topicsQuery({ ...commonApiProps, topic_type: 'artist_group,artist_person' }, { select: denormalizeData }),
      topicsQuery({ ...commonApiProps, topic_type: '-news,artist_group,artist_person' }, { select: denormalizeData }),
    ],
  }));

  return {
    typeSwitcherNews,
    typeSwitcherStars,
    typeSwitcherArticles,
    isLoading: switcherResultsAreLoading,
  };
}
