import { Component } from 'react';
import PropTypes from 'prop-types';

import { throttle } from 'core/libs/lodash';

import withRequestId from 'core/components/App/requestId/withRequestId';

import { callYaMetrikaMethod } from 'core/utils/counters-helper';


/**
 * @author Artyom Gavrilyuk <a.gavrilyuk@rambler-co.ru>
 */
class YaMetrikaScrollPercent extends Component {
  static propTypes = {
    wrapperRef: PropTypes.object,
    requestId: PropTypes.string,
  };

  static contextTypes = {
    counterIds: PropTypes.shape({
      yandexMetrika: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
    }),
  };

  componentDidMount() {
    const {
      wrapperRef,
    } = this.props;

    if (wrapperRef?.current) {
      this.observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          window.addEventListener('scroll', this.onScroll);
        } else {
          window.removeEventListener('scroll', this.onScroll);
        }
      });
      this.observer.observe(wrapperRef.current);
    } else {
      window.addEventListener('scroll', this.onScroll);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      requestId: prevRequestId,
    } = prevProps;

    const {
      requestId,
    } = this.props;

    if (prevRequestId !== requestId) {
      this.is25Reached = false;
      this.is50Reached = false;
      this.is75Reached = false;
      this.is100Reached = false;
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
    this.observer && this.observer.disconnect();
  }

  is25Reached = false;
  is50Reached = false;
  is75Reached = false;
  is100Reached = false;

  observer = null;

  onScroll = throttle(() => {
    const wrapperContainer = this.props?.wrapperRef?.current;
    const scrollHeight = wrapperContainer?.clientHeight || Math.max(
      document.body.scrollHeight, document.documentElement.scrollHeight,
      document.body.offsetHeight, document.documentElement.offsetHeight,
      document.body.clientHeight, document.documentElement.clientHeight
    );
    const scrollPosition = window.pageYOffset + window.innerHeight - (wrapperContainer?.offsetTop || 0);
    const percentScrolled = (scrollPosition / scrollHeight) * 100;

    if (percentScrolled < 25) return;

    const metrikaIds = this.context.counterIds.yandexMetrika;

    if (!this.is25Reached && percentScrolled >= 25) {
      try {
        callYaMetrikaMethod(metrikaIds, 'reachGoal', 'pageScroll_25');
        this.is25Reached = true;
      } catch (e) {
        console.error(e);
      }
      return;
    }

    if (!this.is50Reached && percentScrolled >= 50) {
      try {
        callYaMetrikaMethod(metrikaIds, 'reachGoal', 'pageScroll_50');
        this.is50Reached = true;
      } catch (e) {
        console.error(e);
      }
      return;
    }

    if (!this.is75Reached && percentScrolled >= 75) {
      try {
        callYaMetrikaMethod(metrikaIds, 'reachGoal', 'pageScroll_75');
        this.is75Reached = true;
      } catch (e) {
        console.error(e);
      }
    }

    if (!this.is100Reached && percentScrolled >= 100) {
      try {
        callYaMetrikaMethod(metrikaIds, 'reachGoal', 'pageScroll_100');
        this.is100Reached = true;
      } catch (e) {
        console.error(e);
      }
      this.observer && this.observer.disconnect();
    }
  }, 100);

  render() {
    const {
      children,
      wrapperRef,
    } = this.props;

    if (!children) return null;

    return (
      <div ref={wrapperRef}>
        {children}
      </div>
    );
  }
}

export default withRequestId(YaMetrikaScrollPercent);
