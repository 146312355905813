import { Fragment } from 'react';
import PropTypes from 'prop-types';

import ListPage from 'core/components/ListPage';
import EmptyWrapper from 'core/components/EmptyWrapper';
import PageLoader from 'core/components/Loader/PageLoader';

import { denormalizeData } from 'core/utils/api';
import modelPropTypes, {
  topicAttributes,
} from 'core/utils/prop-types/model';

import RubricTopics from 'site/components/RubricTopics';
import NoMaterials from 'site/components/NoMaterials';
import Noindex from 'site/components/Noindex';

import { RUBRICS_PAGE_LIMIT, QUERY_TYPE_MAP } from 'site/constants';


function TopicsList(props) {
  const {
    isLoading,
    topicsData: {
      meta,
      ...rawData
    },
    type,
  } = props;

  const topics = denormalizeData(rawData);
  const topicType = QUERY_TYPE_MAP.has(type) ? type : 'news';

  const noindexElement = (
    <Noindex
      topicsLength={meta.filtered_count}
    />
  );

  if (!isLoading && !topics?.length) {
    return (
      <Fragment>
        {noindexElement}
        <NoMaterials />
      </Fragment>
    );
  }

  return (
    <ListPage
      content={topics}
      meta={meta}
      limit={RUBRICS_PAGE_LIMIT}
      skipRcm
      isLoading={isLoading}
    >
      {({ content }) => {
        if (isLoading) return <PageLoader />;
        return (
          <EmptyWrapper>
            {noindexElement}
            <RubricTopics
              topics={content}
              topicType={topicType}
              isLoading={isLoading}
            />
          </EmptyWrapper>
        );
      }}
    </ListPage>
  );
}

TopicsList.propTypes = {
  isLoading: PropTypes.bool,
  topicsData: PropTypes.shape({
    data: PropTypes.arrayOf(modelPropTypes(topicAttributes)),
    meta: PropTypes.shape({
      filtered_count: PropTypes.number,
    }),
  }),
  type: PropTypes.string,
};

TopicsList.defaultProps = {
  topicsData: {
    content: [],
    meta: {},
  },
};

export default TopicsList;
