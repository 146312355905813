import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';
import { topicQuery } from 'core/queries/topic';

import Topic from 'core/components/Topic';
import LeaderBoardCore from 'core/components/GameCompare/LeaderBoard';
import Page from 'core/components/Page';
import PageLoader from 'core/components/Loader/PageLoader';

import withTheme from 'core/components/theme';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import resolveRelationships from 'core/utils/relationships';

import {
  MaxWidthWrapper,
  Indent,
  PageIndent,
} from 'site/components/Wrappers';
import TopicHeader from 'site/pages/topic/TopicHeader';


const relationships = resolveRelationships(['content'], {});

function LeaderBoard({ isMobile, theme, location, match, history }) {
  const { data: content, isLoading } = useRequest(topicQuery({ location, match, history }));

  if (isLoading) return <PageLoader />;

  return (
    <Page>
      <style jsx>{`
        .announce
          font 18px/27px ${theme.fonts.text}
          color ${theme.colors.primary}
          :global(.mobile) &
            font 16px/22px ${theme.fonts.text}
      `}</style>
      <PageIndent>
        <Topic content={content}>
          {topic => {
            const {
              content: {
                widgets,
              },
            } = relationships(topic);

            if (widgets.length === 0) return null;

            const {
              final_announce: finalAnnounce,
            } = widgets[0].attributes;

            return (
              <MaxWidthWrapper>
                <Indent top={20} bottom={isMobile ? 80 : 50} >
                  <TopicHeader content={topic} isGameCompare />
                  <Indent top={20} />
                  <div className='announce'>{finalAnnounce}</div>
                  <Indent top={isMobile ? 35 : 25} />
                  <LeaderBoardCore content={topic} />
                </Indent>
              </MaxWidthWrapper>
            );
          }}
        </Topic>
      </PageIndent>
    </Page>
  );
}

LeaderBoard.propTypes = {
  isMobile: PropTypes.bool,
  theme: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
};

export default withBreakpoint(withTheme(LeaderBoard));
