export const host = 'go.zvuk.com';
export const siteUrl = 'https://' + host;

// Конфиг для фильтра по символам
export const symbolsMap = {
  ru: {
    label: 'Рус',
    selectLabel: 'Русский язык',
    position: 0,
  },
  en: {
    label: 'Eng',
    selectLabel: 'Английский язык',
    position: 1,
  },
  other: {
    label: '1-9,$#%',
    selectLabel: 'Спец. символы',
    position: 2,
  },
};

// Конфиг типов для фильтра списковых страниц

export const QUERY_TYPE_MAP = new Map([
  [
    'news',
    {
      title: 'Новости',
      requestType: 'news',
      withSearch: false,
    },
  ], [
    'articles',
    {
      title: 'Статьи',
      requestType: '-news,artist_group,artist_person',
      withSearch: false,
    },
  ], [
    'stars',
    {
      title: 'Артисты',
      requestType: 'artist_group,artist_person',
      tags: ['genre', 'geo'],
      withSearch: true,
    },
  ],
]);

export const SUBSCRIPTION_FORM_ID = 'subscriptionForm';
export const SUBSCRIPTION_IS_VERIFIED = 'SUBSCRIPTION_IS_VERIFIED';

export const STARS_PAGE_LIMIT = 12;
export const STARS_PAGE_LIMIT_MOBILE = 8;

export const WIKI_SECTION = 'wikiSection';

export const ACTIVE_LANGUAGE_BUTTON_ID = 'activeLanguageButton';

export const METRICS = {
  subscribe_popup_showed: 'subscribe_popup_showed',
  subscribe_popup_closed: 'subscribe_popup_closed',
  subscribe_form_submit: 'subscribe_form_submit',
  inpagesubscribe_form_submit: 'inpagesubscribe_form_submit',
  mail_subscribed: 'mail_subscribed',
};

// indents
export const SIDE_INDENT = 40;
export const SIDE_INDENT_MOBILE = 20;
export const VERTICAL_INDENT = 20;
export const VERTICAL_INDENT_MOBILE = 10;

// limits
export const RUBRICS_PAGE_LIMIT = 10;
export const NEWS_RUBRIC_LIMIT = 9;
export const NEWS_LIMIT = 12;

// sizes
export const LAYOUT_MAX_WIDTH = 1000;
export const LAYOUT_MAX_WIDTH_MOBILE = 600;
export const TOPIC_MAX_WIDTH = 700;
