import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';

import Page from 'core/components/Page';
import { PageIndent } from 'core/components/Wrappers';
import { withBreakpoint } from 'core/components/breakpoint';

import ListPageWithFilters from 'site/components/ListPageWithFilters';

import listPageTopicsQuery from 'site/queries/listPageTopicsQuery';

import { STARS_PAGE_LIMIT, STARS_PAGE_LIMIT_MOBILE } from 'site/constants';


const title = 'Биографии исполнителей и истории музыкальных групп';
const description = 'Биографии, истории из жизни российских и зарубежных музыкальных исполнителей и групп. Только подлинная и достоверная информация о звездах музыкальной индустрии.';

function StarsPage({ isDesktop, match, location }) {
  const { data: topicsData, isLoading: topicDataIsLoading } = useRequest({
    ...listPageTopicsQuery({
      match,
      location,
      limit: isDesktop ? STARS_PAGE_LIMIT : STARS_PAGE_LIMIT_MOBILE,
    }),
  });

  return (
    <Page title={title} description={description}>
      <PageIndent>
        <ListPageWithFilters
          topicsData={topicsData}
          isLoading={!!topicDataIsLoading}
        />
      </PageIndent>
    </Page>
  );
}

StarsPage.propTypes = {
  isDesktop: PropTypes.bool,
  match: PropTypes.object,
  location: PropTypes.object,
};

export default withBreakpoint(StarsPage);
